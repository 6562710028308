import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { removeDataAttributes } from "../../comQueryStructure";
import Select from 'react-select';

export const PropertyTypes = (props) => {
  const data = useStaticQuery(graphql`  
  query PropertyTypesQuery { 
    glstrapi {
      siteConfig {

        data {
          id
          attributes {  
            Commercial_Property_Types(pagination: {limit:50}) {
              Label
              Value
            }
            Commercial_Rental_Property_Types(pagination: {limit:50}) {
              Label
              Value
            }
            Residential_Property_Types(pagination: {limit:50}) {
              Label
              Value
            }
            Residential_Rental_Property_Types(pagination: {limit:50}) {
              Label
              Value
            }
          }
        }
      }
    }
  }
`)
const typeListData = removeDataAttributes(data)
  let typeList = typeListData.glstrapi.siteConfig;
  console.log('PropertyTypes', data.glstrapi.siteConfig)
  let typeName = "Residential_Property_Types"
  if (props.propertyTypeName) {
    typeName = props.propertyTypeName
  }
  return (
    <>
      <Select
        options={typeList[typeName].map(({ Label, Value }) => ({ label: Label, value: Value }))}
        {...props}
      />
    </>
  )
}

 