import React, {useEffect, useState} from "react"
import { Link } from "@StarberryUtils";
import {Button, ButtonGroup, Nav,Form, Container,Dropdown,Row,Col } from 'react-bootstrap';
import ContactCard from "../../Home/ContactCard/ContactCard"
// Images

import  './ResultList.scss';

import {NoResultProperties} from '../../../queries/common_use_query';
import { ImageModule } from "../../../modules/Image_Module";
import { removeDataAttributes } from "../../../comQueryStructure";




const ResultList = ( props ) => {

    const[department, setDepartment] = useState('residential');

    useEffect(()=>{
        props.department && setDepartment(props.department);
    },[props.department]);

    const {loading:loading, error:error, data:data} = NoResultProperties(props.status, department);
    const no_data = removeDataAttributes(data?.properties)
    

    return(
        <div className="result-list">
        <Container>
            {
                no_data && no_data.length > 0 && <>
            
                <p class="text-center results-page-wrapper">We have selected some of our showcase properties for you to browse below. Alternatively, you can search again in the bar above.</p>
                <div className="result-cards">
                <Row>
                    {
                         no_data.map((hit, index) => {

                            //console.log("item_images",hit.images[0])

                            return(
                                <Col className={`active-tag ${hit.status === 'sold' || hit.status === 'rented' ? 'sold' : ''}`} md={6} lg={4}>
                                    <Link to={'/'+props.uriStr+'/'+hit.slug+'-'+hit.id}>
                                         <div className="animated">
                                            <ContactCard
                                                cardImg={hit.images[0]}
                                                cardTitle={hit.title+' in '+hit.display_address}
                                                date={hit.price.toLocaleString()+" QR"}
                                                cardId={hit.id}
                                                cardTag={hit.status === 'sold' || hit.status === 'rented' ? hit.status : ''}
                                            />
                                         </div>
                                    </Link>
                                </Col>
                            )
                        })
                    }
                    
                </Row>
            </div>
            </>
        }
        </Container>

            
        </div>
    )
}

export default ResultList