import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react"
import { Container, Row, Col, Badge, Form } from 'react-bootstrap';
import HTMLParser from 'html-react-parser';

// Images
import { ShowProcessedImage } from '../../../components/common/ggfx-client/module/components/show-image';
import imageConfig from "../../../../static/images/config.json"

import Calendly from "../../../modules/calendly-link";
import { youtube_parser, numericWithDecimal } from "../../common/utils";
import Modal from "react-bootstrap/Modal"
import { useLocation } from "@reach/router";
import RequestDetails from "../../../components/forms/request-details-form-offplan"
// Style
import './ProjectList.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import PlayVideo from "../../Play/PlayVideo";
import { ImageModule } from "../../../modules/Image_Module";
import SelectBox from "../../Home/SelectBox/SelectBox"

const ProjectListOffplan = (props) => {

  const [isPlay, setPlay] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [data, setData] = useState([]);
  const [youtubeId, setYoutubeId] = useState('');

  const [isOpen,setIsOpen] = useState(false);
  const location = useLocation();
  const [setlocation, setNewLocation] = useState(null);


  const [property_data,setProperty_data] = useState("");
  const [form_lables, setFormlables] = useState("");

  const openVideo = (youtube_id) => {
    setPlay(true);
    setYoutubeId(youtube_id); } 

    const openModalForm = (e, item) => {
      e.preventDefault();
  
      //console.log("modal_heading", modal_heading, modal_type);
      setIsOpen(true);
      setProperty_data(item)
      setFormlables(item.name)      
      //setModalHeading(modal_heading);
      //setModalType(modal_type);
    }


    var to_email_id = process.env.GATSBY_MAIL_TO_QATAR ;

  //console.log("ProjectListOffplan", props?.data) 

  return (
    <div className="benefit-block project-list-block">
       <div className="animated">
       
          {props?.data && props?.data?.length > 0 ? (

            props?.data.map((item, index) => {

              var youtube_url = item.Youtube_Video_URL && youtube_parser(item.Youtube_Video_URL);

              let processedImages = JSON.stringify({});
              if (item.imagetransforms && item.imagetransforms.Title_Image_Transforms) {
                processedImages = item.imagetransforms.Title_Image_Transforms;
              }
              var price_start = item.Price_Starting_From && numericWithDecimal(item.Price_Starting_From);

              let slug = '/new-projects-in-dubai/'
              if(item.region === "Qatar" && item.property_status === "Rent"){
                slug = '/rent-new-projects-in-qatar/'
              }else if(item.region === "Qatar" && item.property_status === "Sale"){
                slug = '/buy-new-projects-in-qatar/'
              }

              return (
                <Row className="project-section-list" key={index}>
                  <Col lg="5">
                    <div className="benefit-block-img img-zoom">
                      <a href={ item.region === 'Qatar' && youtube_url ? "javascript:void(0)" : `${slug}${item.url}`}  onClick={item.region === 'Qatar' && youtube_url ? () => openVideo(youtube_url) : null}>
                      { /*
                        item.Title_Image ?
                          <picture>
                               <img className="" src={item.Title_Image.url} alt="" /> 
                          </picture> : <span className="noImg"></span>
                      */}
                        {
                        item.images && (item.images.length > 0) ? (
                        <>
                       
                          <ShowProcessedImage images={item.images[0]} attr={{ className: 'propery-img', alt: ''}} transforms={imageConfig.property.searchResultsoffplan.sizes} />
                        </>
                        ) : (
                        <></>
                        )
                        }
                       </a>

                      {item.region === 'Qatar' && youtube_url  ? <strong  className="play-btn-circle" onClick = {e => openVideo(youtube_url)} ><i className="icon-play"></i></strong> : ''}
                     
                    </div>
                  </Col>
                  <Col lg="7">
                    <div className="benefit-block-content">

                      <h2>{item.name}</h2>
                      {/* <p>{item.Short_Description}</p> */}
                      <ul className="project-info">
                        <li>
                          <strong>Project Type:</strong>
                          <span>{item.project_type}</span>
                        </li>
                        <li>
                          <strong>Category:</strong>
                          <span>{item.category}</span>
                        </li>
                        <li>
                          <strong>Listing Status:</strong>
                          <span>{item.listing_status}</span>
                        </li>
                        <li>
                          <strong className="project-info-title">Price Range:</strong>
                          {item.price &&
                             <span>QR {item.price.toLocaleString()}</span>
                          }  
                          <Badge variant="secondary" className={item.priority === "Medium" ? "warning" : item.priority === "High" ? "danger" : item.priority === "Low" ? "info" : "default"}>{item.priority}</Badge>
                        </li>
                      </ul>
                      <div className="btn-list">                        
                        {(item.property_category === "Sold Out" && item.resale === true) &&
                          <Link to={`/properties/for-sale/in-doha-qatar/`} className="btn btn-primary">
                          <span>Resale</span>
                          </Link>
                        }
                        <Link to={`${slug}${item.url}`} className="btn btn-primary">
                          <span>Find out more</span>
                        </Link>
                        {(item.property_category === "Pre-Launch" || item.property_category === "Active Projects") &&
                        <>
                           <a href="javascript:;" className="btn btn-primary" onClick={(e) => openModalForm(e, item)}> <span>Register your Interest</span></a>
                        </>                         
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
              )

            })

          ) : (
            props?.data && props?.data?.newProjects?.length > 0 ? (

              props.data.newProjects.map((item, index) => {

                var youtube_url = item.Youtube_Video_URL && youtube_parser(item.Youtube_Video_URL);
  
                let processedImages = JSON.stringify({});
                if (item.imagetransforms && item.imagetransforms.Title_Image_Transforms) {
                    processedImages = item.imagetransforms.Title_Image_Transforms;
                }
                var price_start = item.Price_Starting_From && numericWithDecimal(item.Price_Starting_From);
  
                return(
                  <Row className="project-section-list">
                    <Col lg="5">
                    <div className="benefit-block-img img-zoom">
                    {
                        item.images && (item.images.length > 0) ? (
                        <>
                          <ShowProcessedImage images={item.images[0]} attr={{ className: 'propery-img', alt: ''}} transforms={imageConfig.property.searchResultsoffplan.sizes} />
                        </>
                        ) : (
                        <></>
                        )
                        }
                      
                      {item.region === 'Qatar'? <strong href="" className="play-btn-circle" onClick = { e => openVideo(youtube_url)}><i className="icon-play"></i></strong>: ''}
                    </div>
                    </Col>
                    <Col lg="7">
                    <div className="benefit-block-content">
                    
                    <h2>{item.Name}</h2>
                    {/* <p>{item.Short_Description}</p> */}
                    <ul className="project-info">
                      <li>
                        <strong>Project Type:</strong>
                        <span>{item.project_type}</span>
                      </li>
                      <li>
                        <strong>Category:</strong>
                        <span>{item.category}</span>
                      </li>
                      <li>
                        <strong>Listing Status:</strong>
                        <span>{item.listing_status}</span>
                      </li>
                      <li>
                        <strong className="project-info-title">Price Range:</strong>
                        {item.price &&
                             <span>QR {item.price.toLocaleString()}</span>
                        }    
                        <Badge variant="secondary" className={item.priority === "Medium" ? "warning" : item.priority === "High" ? "danger" : item.priority === "Low" ? "info" : "default"}>{item.priority}</Badge>
                      </li>                      
                    </ul>                   
                    
                    <div className="btn-list">                        
                        {(item.property_category === "Sold Out" && item.resale === true) &&
                          <Link to={`/properties/for-sale/in-doha-qatar/`} className="btn btn-primary">
                          <span>Resale</span>
                          </Link>
                        }
                        <Link to={`${item.url}`} className="btn btn-primary">
                          <span>Find out more</span>
                        </Link>
                        {(item.property_category === "Pre-Launch" || item.property_category === "Active Projects") &&
                        <>
                           <a href="javascript:;" className="btn btn-primary" onClick={(e) => openModalForm(e, item)}> <span>Register your Interest</span></a>
                        </>                         
                        }
                      </div>
                    </div>
                    </Col>
                  </Row>
                )  
                
              }) 
              
            ) : (
              <span className="text-center col-12">No projects found.</span>
            )
          )
          }
      
       </div>
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={youtubeId}
        isAutoPlay={1}
      />


<Modal
      id="valuation_modal"
      show={isOpen}
      onHide={() => setIsOpen(false)}
      backdrop="static"
      keyboard={false}
      className="member-contact-modal"
    >
      {/* <Button variant="" onClick={this.closeModal} className="p-0 closepopup"><i className="icon icon-close-popup"></i></Button> */}
      <Modal.Header closeButton className="contact-close-btn memberPopup">
        <Modal.Title className="w-100"><h3 className="text-center content-primary-color mb-0">{form_lables}- Request Details</h3></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RequestDetails to_email_id={to_email_id} formName={`New Project - Request Details`} formLabel={form_lables} property_data={property_data}  formType={'request-details'} />
      </Modal.Body>
</Modal>

    </div>
  )
}

export default ProjectListOffplan